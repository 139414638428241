<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">

          <router-link :to="'/monitoring/info/' + (configId ? configId : '')" class="back-link">
            <svg fill="none" height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" fill="#0033A0"/>
            </svg>
            назад
          </router-link>
          <h1>{{ direction.name }}</h1>

          <template v-for="action in direction.actions">
            <h2 :key="'h'+action.id">{{ action.name }}</h2>

            <table :key="'t'+action.id" class="monitoring-table">
              <thead>
              <tr>
                <th>Этап</th>
                <th>Дата завершения</th>
                <th>Сумма</th>
                <th>Дополнительная информация</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="stage in action.stages" :key="action.id + '.' + stage.id">
                <td :class="getValue(action.id, stage.id).endDate ? 'green' : 'gray'"><span class="bullet"></span>
                  {{ stage.name }}
                </td>
                <td>
                  <EditableDateField
                      :attrs="{actionId: action.id, stageId: stage.id}"
                      :editable="editable"
                      :input="getValue(action.id, stage.id).endDate"
                      :output-setter="setEndDate"
                  ></EditableDateField>
                </td>
                <template v-if="getValue(action.id, stage.id).endDate">
                  <td>
                    <EditableTextField
                        v-if="showPriceTypes.includes(stage.type)"
                        :attrs="{actionId: action.id, stageId: stage.id}"
                        :editable="editable"
                        :input="getValue(action.id, stage.id).price"
                        :output-setter="setPrice"
                        nolabel
                        write-text="Укажите общую стоимость (руб.)"
                    ></EditableTextField>
                    <span v-else>&mdash;</span>
                  </td>
                  <td>
                    <EditableTextAreaField
                        v-if="showPlanUrlTypes.includes(stage.type)"
                        :attrs="{actionId: action.id, stageId: stage.id}"
                        :editable="editable"
                        :input="getValue(action.id, stage.id).planUrl"
                        :output-setter="setPlanUrl"
                        label="Ссылки на позиции плана (zakupki.gov.ru)"
                        write-text="Позиции плана закупок"
                    ></EditableTextAreaField>
                    <EditableTextAreaField
                        v-if="showNotificationUrlTypes.includes(stage.type)"
                        :attrs="{actionId: action.id, stageId: stage.id}"
                        :editable="editable"
                        :input="getValue(action.id, stage.id).notificationUrl"
                        :output-setter="setNotificationUrl"
                        label="Ссылки на размещенные закупки (zakupki.gov.ru)"
                        write-text="Размещенные закупки"
                    ></EditableTextAreaField>
                    <div
                        v-if="showNotificationUrlTypes.includes(stage.type) && getValue(action.id, stage.id).notificationUrl">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a href="#"
                             @click.prevent=""
                             color="primary"
                             dark
                             v-bind="attrs"
                             v-on="on"
                          >
                            Информация о закупке
                          </a>
                        </template>
                        <span>
                          <NotificationInfo :url="getValue(action.id, stage.id).notificationUrl"></NotificationInfo>
                        </span>
                      </v-tooltip>
                    </div>
                    <EditableTextAreaField
                        v-if="showArticleNameTypes.includes(stage.type)"
                        :attrs="{actionId: action.id, stageId: stage.id}"
                        :editable="editable"
                        :input="getValue(action.id, stage.id).articleName"
                        :output-setter="setArticleName"
                        label="Названия материалов (статей, мероприятий), даты размещения"
                        write-text="Перечень материалов"
                    ></EditableTextAreaField>
                    <EditableTextAreaField
                        v-if="showArticleUrlTypes.includes(stage.type)"
                        :attrs="{actionId: action.id, stageId: stage.id}"
                        :editable="editable"
                        :input="getValue(action.id, stage.id).articleUrl"
                        :output-setter="setArticleUrl"
                        label="Ссылки на материалы"
                        write-text="Ссылки на материалы"
                    ></EditableTextAreaField>
                    <EditableTextAreaField
                        v-if="showDocNameTypes.includes(stage.type)"
                        :attrs="{actionId: action.id, stageId: stage.id}"
                        :editable="editable"
                        :input="getValue(action.id, stage.id).docNameAndDetails"
                        :output-setter="setDocument"
                        label="Документ"
                        write-text="Реквизиты документа"
                    ></EditableTextAreaField>
                    <v-checkbox
                        v-if="showMultiplePurchasesTypes.includes(stage.type)"
                        :input-value="getValue(action.id, stage.id).multiplePurchases"
                        :readonly="!editable"
                        label="Имеется несколько закупок"
                        @change="setMultiplePurchases($event, {actionId: action.id, stageId: stage.id})"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="showContractProblemsTypes.includes(stage.type)"
                        :input-value="getValue(action.id, stage.id).contractProblems"
                        :readonly="!editable"
                        label="Есть расторгнутые или исполненные частично контракты"
                        @change="setContractProblems($event, {actionId: action.id, stageId: stage.id})"
                    ></v-checkbox>

                    <EditableTextAreaField
                        :attrs="{actionId: action.id, stageId: stage.id}"
                        :editable="editable"
                        :input="getValue(action.id, stage.id).comment"
                        :output-setter="setComment"
                        label="Комментарий"
                        write-text="Напишите комментарий"
                    ></EditableTextAreaField>
                  </td>
                  <td>
                    <v-icon style="cursor: pointer" v-if="getValue(action.id, stage.id).endDate"
                            @click="deleteValue(action.id, stage.id)" class="icon-item icon-delete-outline"></v-icon>
                  </td>
                </template>
                <template v-else>
                  <td></td>
                  <td></td>
                  <td></td>
                </template>
              </tr>
              </tbody>
            </table>
          </template>


          <div v-if="editable" class="save-buttons">
            <v-btn outlined @click="$router.push('/monitoring/info/' + (configId ? configId : ''))">отмена</v-btn>
            <v-btn color="primary" @click="submit">Сохранить изменения</v-btn>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<script>
import EditableDateField from "./elements/EditableDateField";
import EditableTextAreaField from "./elements/EditableTextAreaField";
import EditableTextField from "./elements/EditableTextField";
import NotificationInfo from "./elements/NotificationInfo";

export default {
  name: "MonitoringEdit",
  props: [],
  components: {EditableTextField, EditableTextAreaField, EditableDateField, NotificationInfo},
  data() {
    return {
      configId: null,
      config: null,
      direction: null,
      values: null,
      apiLoaded: false,
      errorText: null,
      showPriceTypes: [2, 3, 4, 5],
      showPlanUrlTypes: [2],
      showNotificationUrlTypes: [3],
      showArticleNameTypes: [6],
      showArticleUrlTypes: [6],
      showDocNameTypes: [1],
      showMultiplePurchasesTypes: [2, 3],
      showContractProblemsTypes: [5],
      editable: false,
      apiFreeze: false, //во избежание многократного нажатия кнопки сохранить
      departmentId: null,
    };
  },
  methods: {
    async loadConfig() {
      let req
      if (this.configId == null) {
        req = await this.$getApi("/monitoring/getActiveConfig")
      } else {
        req = await this.$getApi("/monitoring/getConfig", {id: this.configId})
      }
      if (req.ok) {
        this.config = req.payload
        this.config.directions = JSON.parse(this.config.directionsJson)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    extractDirection() {
      for (let direction of this.config.directions) {
        if (direction.id == this.directionId) {
          this.direction = direction
          return
        }
      }
      this.errorText = "Ошибка: некорректный номер направления или конфигурационный файл"
    },

    async loadStageValues() {
      let req = await this.$getApi("/monitoring/getDirectionStageValues",
          {userName: this.departmentId, configId: this.config.id, directionId: this.directionId})
      if (req.ok) {
        this.values = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    deleteValue(actionId, stageId) {
      for (let i = 0; i < this.values.length; i++) {
        if (this.values[i].directionId === this.directionId && this.values[i].actionId === actionId && this.values[i].stageId === stageId) {
          this.values.splice(i, 1)
        }
      }
      this.$forceUpdate()
    },

    async submit() {
      if (this.apiFreeze) {
        return
      }
      this.apiFreeze = true
      // console.log({
      //   userName: this.departmentId, //this.$user.username,
      //   configId: this.config.id,
      //   directionId: this.direction.id,
      //   stageValues: this.values
      // })

      /*
      * Костыль прям замены старых username на новые (id ЦЗН)
      * */
      this.values.forEach(it => {
        if (isNaN(it.userName)) {
          it.userName = this.departmentId;
        }
      });

      let validationResult = this.validateStageValues()
      if (validationResult === true) {
        let req = await this.$postApi("/monitoring/saveDirectionStageValues", {
          userName: this.departmentId, //this.$user.username,
          configId: this.config.id,
          directionId: this.direction.id,
          stageValues: this.values
        })
        if (req.ok) {
          this.$router.push('/monitoring/info/' + (this.configId ? this.configId : ''))
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        }
      } else {
        this.errorText = validationResult
      }
      this.apiFreeze = false
    },

    validateStageValues() {
      return true
    },

    getValue(actionId, stageId) {
      for (let v of this.values) {
        if (v.directionId === this.directionId && v.actionId === actionId && v.stageId === stageId) {
          return v
        }
      }
      return {}
    },

    setField(actionId, stageId, field, value) {
      let objectValue = this.getValue(actionId, stageId)
      if (objectValue.directionId == null) {
        let obj = {
          userName: this.departmentId, //this.$user.username,
          configId: this.config.id,
          directionId: this.directionId,
          actionId: actionId,
          stageId: stageId,
        }
        obj[field] = value
        this.values.push(obj)
      } else {
        objectValue[field] = value
      }
      this.$forceUpdate();
    },

    setEndDate(endDate, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "endDate", endDate)
    },
    setComment(comment, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "comment", comment)
    },
    setDocument(val, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "docNameAndDetails", val)
    },
    setPrice(val, attrs) {
      if (val != null && val != '' && !this.isNumeric(''+val)) {
        alert('Допускаются только числовые значения')
        this.$forceUpdate()
        return
      }
      this.setField(attrs.actionId, attrs.stageId, "price", val)
    },
    setMultiplePurchases(val, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "multiplePurchases", val)
      // console.log(this.values)
    },
    setPlanUrl(val, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "planUrl", val)
    },
    setNotificationUrl(val, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "notificationUrl", val)
    },
    setContractProblems(val, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "contractProblems", val)
    },
    setArticleName(val, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "articleName", val)
    },
    setArticleUrl(val, attrs) {
      this.setField(attrs.actionId, attrs.stageId, "articleUrl", val)
    },

    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    this.configId = this.$route.params.configId;
    this.directionId = +this.$route.params.directionId;
    this.departmentId = this.$route.params.departmentId;
    await this.loadConfig();
    this.extractDirection();
    await this.loadStageValues();
    this.editable = (this.config.active) &&
                    (this.$user.role==='ROLE_CZN_MANAGER' ||
                     this.$user.role==='ROLE_CZN_TERRITORIAL' ||
                        this.$user.role==='ROLE_ADMIN' ||
                     this.$user.role==='ROLE_REGIONAL_AGENCY');
    this.apiLoaded = true;
  }
};
</script>


<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>
